<template>
    <div class="otherProducts">
        <div class="textBlock">
            <h3 class="title-item">Popüler Ürünler</h3>
            <p>En popüler oyunların ürünlerini her zaman en iyi fiyata satın alın!</p>
        </div><br />
        <carousel v-bind="settings" :breakpoints="breakpoints">
            <slide v-for="item in shuffled" :key="item">
                <router-link :to="'/' + item.name">
                    <img style="width: 200px;" :src="item.pic_Square" />
                </router-link>
            </slide>

            <template #addons>
            </template>
        </carousel>
    </div>
</template>
 
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

export default
    {
        name: 'OtherProducts',
        components: {
            Carousel,
            Slide,
        },
        data() {

            return {
                settings: {
                    wrapAround: true,
                    autoplay: 2000,
                    itemsToShow: 1,
                    snapAlign: 'center',
                    pauseAutoplayOnHover: true,
                },
                breakpoints: {
                    400: { itemsToShow: 1.7 },
                    450: { itemsToShow: 2 },
                    500: { itemsToShow: 2.1 },
                    550: { itemsToShow: 2.5 },
                    600: { itemsToShow: 2.5 },
                    700: { itemsToShow: 3.2 },
                    900: { itemsToShow: 4 },
                    1000: { itemsToShow: 4.5 },
                }
            }
        },
        computed:
        {
            shuffled() {
                return this.$store.state.products.all
                    .map(value => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value);
            }
        }
    }
</script>



<style>
.otherProducts {
    max-width: 1000px;
    margin: auto;

    .slider {
        width: inherit;
        height: inherit;
    }
}
</style>
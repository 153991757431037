import Api from "./baseApi";
import { store } from "@/store"

const state = {
    user: {},
    isLogin: false,
    isError: false,
    isLoading: false,
};

const getters = {
    setting: (state) => {
        return state.setting;
    },
};

const actions = {
    login: async (context, userId) => {
        userId = userId.toUpperCase();
        context.commit("setLoading");
        context.commit("setLogin", (await Api.get(`/api/user/${userId}`)).data);
    },
    logout: (context) => {
        context.commit("setLogout");
    },
};

const mutations = {
    setLoading(state) {
        state.isError = false;
        state.isLoading = true;
    },
    setLogin(state, model) {
        state.isError = model.userId == null;
        if (!state.isError) {
            localStorage.setItem("RestPokerId", model.userId);
            state.isLogin = true;
            state.user = model;
            //store.state.loginDialog = false
        }
        state.isLoading = false;
    },
    setLogout(state) {
        localStorage.removeItem("RestPokerId");
        state.isLogin = false;
        state.user = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import Abouts from '../views/About'
import Product from '../views/Product'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: "/order/:id",
    name: "order",
    component: () => import('../views/Order.vue')
  },
  {
    path: '/:id',
    name: 'products',
    component: Home
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact')
  },
  {
    path: '/about',
    name: 'about',
    component: Abouts
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {

//   //console.log(to);
//   //document.title = to.meta.title ? to.meta.title : "Some Default Title"

//   next()
// })

// router.afterEach((to, from, next) => {

//   console.log(to);
//   //document.title = to.meta.title ? to.meta.title : "Some Default Title"

//   next()
// })

export default router



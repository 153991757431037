<template>
    <div rounded v-if="state.loginDialog" class="pa-4" style="max-width: 800px;margin: 20px auto 50px auto;"
        max-width="800">
        <v-card color="#212562" rounded="xl">
            <v-toolbar flat color="#4c4ccf" fixed>
                <v-toolbar-title color="white"><i class="fa-solid fa-file mr-2" />{{ $t("login.title") }}</v-toolbar-title>
                <v-spacer />

                <img src="https://cdn.restchip.com/assets/img/close.png" class="close"
                    @click="() => state.loginDialog = false">

            </v-toolbar>

            <v-card-text class="px-2">
                <v-container fluid class="px-2">
                    <div v-if="!state.restpoker.isLogin" style="text-align: center; ">
                        <span>{{ $t("login.desc1") }}</span>
                        <p>{{ $t("login.desc2") }}</p>

                        <!-- <input type="text" v-model="restUserId" class="inputID" placeholder="ID: IXOB4I"
                            style="max-width: 200px;" /> -->
                        <br /><br />

                        <v-text-field v-bind="attrs" rounded variant="solo-filled" solo-inverted single-line hide-details
                            style="margin: auto;max-width: 400px;" v-model="restUserId" placeholder="ID: IXOB4I">

                            <template v-slot:append-inner>
                                <div style="margin-right: -7px;">
                                    <load-ring v-if="state.restpoker.isLoading" class="loadrestPokerUser"
                                        style="width: 100px;margin:auto;" />
                                    <button v-else class="buttonSend" @click="login"
                                        style="width: 100px;margin-left: -25px;">
                                        {{ $t("global.login") }}
                                    </button>
                                </div>
                            </template>

                            <template v-slot:prepend-inner>
                                <font-awesome-icon :icon="['fas', 'user']" size="2xl" class="mr-2"
                                    style="margin-left: 5px;  " />
                            </template>
                        </v-text-field>


                        <p v-if="state.restpoker.isError" style="color:#fda2a2;text-align:center;margin-top: 10px;">
                            {{ $t("login.noUser") }}
                        </p>
                    </div>
                    <div style="max-width: 600px; margin: auto;">
                        <div style="text-align: center;" v-if="state.restpoker.isLogin">
                            {{ $t("login.title1") }}
                        </div><br />
                        <RestPokerUser class="orderUser" v-if="state.restpoker.isLogin" />
                        <div class="order my-6">
                            <div class="ordersContainer">
                                <div class="orderFlex">
                                    <img :src="state.payments.order.img" class="productImg" />
                                    <div class="productText">
                                        {{ $t("global.chips") }}:
                                        <span style="color: #00ff00;">
                                            ${{ $filters.formatChip(state.payments.order.amount) }}
                                        </span>
                                        <br />
                                        {{ $t("global.price") }}:
                                        <span style="color: #00ffff;">
                                            {{ state.payments.order.localPrice }}
                                        </span>
                                        <br />
                                        <v-chip size="x-large" color="lime" style="height: auto;" class="my-2">
                                            <font-awesome-icon :icon="['fab', 'bitcoin']" size="2xl" class="mr-2"
                                                style="margin-left: -18px; color: orange;" />
                                            {{ state.payments.order.btc }} BTC
                                        </v-chip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="state.restpoker.isLogin">
                            <br />
                            <h4>{{ $t("login.payment") }}</h4>

                            <div class="paymentContainer">
                                <font-awesome-icon :icon="['far', 'circle-dot']" size="2x" style="color: #00ff66;" />
                                <img src="https://cdn.restchip.com/assets/img/btcIcon.png" alt="Success"
                                    style="max-width: 150px;      width: 100%;">
                            </div>

                            <div class="paymentContainer" style="opacity: 0.5;">
                                <font-awesome-icon :icon="['far', 'circle']" size="2x" style="color: #808080;" />
                                <img src="https://cdn.restchip.com/assets/img/VisaLight.svg" alt="Success"
                                    style="max-width: 80px;      width: 100%;">
                                <img src="https://cdn.restchip.com/assets/img/MasterCard.svg" alt="Success"
                                    style="max-width: 80px;      width: 100%;">
                                <img src="https://cdn.restchip.com/assets/img/AmericanExpress.svg" alt="Success"
                                    style="max-width: 80px;      width: 100%;">
                            </div>

                            <div class="paymentContainer" style="opacity: 0.5;">

                                <font-awesome-icon :icon="['far', 'circle']" size="2x" style="color: #808080;" />
                                <img src="https://cdn.restchip.com/assets/img/PayPal.svg" alt="Success"
                                    style="max-width: 150px;      width: 100%;">
                            </div>

                            <div v-show="!form.token" id="captcha" class="captcha"></div>
                            <div v-if="form.token" class="buyBtnContainer">
                                <load-ring v-if="isBuying" />
                                <v-btn v-else size="large" color="green" @click="buyProduct"
                                    style="width: 100%; max-width: 450px;height: 78px;">Devam</v-btn>
                            </div>
                        </div>
                    </div>
                </v-container>
            </v-card-text>
        </v-card>
    </div>
</template>

<script setup>
import { ref, onUpdated } from 'vue'
import { store, state } from '@/store'
import RestPokerUser from '@/components/RestPokerUser';
import router from "@/router";

const restUserId = ref("")
const widgetId = ref()
const isBuying = ref(false)
const form = ref({
    id: 0,//props.product.id,
    clientId: state.clientId,
    price: 0,
    currency: "",
    token: null
})

onUpdated(() => {

    console.log("onUpdated")
    if (!state.loginDialog)
        return

    if (state.restpoker.isLogin)
        loadCaptcha()

})

const loadCaptcha = () => {
    isBuying.value = false
    form.value.token = null

    // if (!widgetId.value)
    //     window.grecaptcha.reset(widgetId.value)
    // else
    //window.grecaptcha.reset()
    widgetId.value = window.grecaptcha.render('captcha', {
        'sitekey': '6LcUhSkpAAAAADUfkGXVdc3gynmTWkGGGsP8OHft',
        "callback": (response) => handleLoadCallback(response),
        "expired-callback": () => handleExpiredCallback(),
        "error-callback": () => handleErrorCalback(),
    });
    handleWidgetId(widgetId.value)
}

const login = async () => {
    if (restUserId.value && restUserId.value.length >= 5) {
        await store.dispatch("restpoker/login", restUserId.value);

        if (state.restpoker.isLogin)
            loadCaptcha()
    }
}

const handleWidgetId = (widgetId) => console.log("Widget ID: ", widgetId);
const handleErrorCalback = () => console.log("Error callback")
const handleExpiredCallback = () => console.log("Expired callback")
const handleLoadCallback = (response) => form.value.token = response



const buyProduct = async () => {

    isBuying.value = true
    form.value.userId = state.restpoker.user.userId
    form.value.price = state.payments.order.price
    form.value.id = state.payments.order.id
    form.value.currency = state.currency

    await store.dispatch("payments/buyProduct", form.value);

    var response = state.payments.response;
    if (response.status !== "success") {
        alert(response.reason);
        return;
    }
    router.push(`/order/${response.token}`)
}

</script>

<style scoped>
.v-dialog {
    background-color: #00000087;
}

.order {
    padding: 0;
}

.ordersContainer {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-radius: 1rem;
    background: #8174E740;
    position: relative;
    justify-content: space-between;
    overflow: hidden;
}

.orderFlex {

    display: flex;
    gap: 10px;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.paymentContainer {
    display: flex;
    gap: 10px;
    background-color: #8174E740;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 1rem;
    position: relative;
    align-content: center;
    align-items: center;
    transition: all 0.2s ease;

}

.paymentContainer:hover {
    background-color: #8174e75b;
}

.buyBtnContainer {
    text-align: center;
    height: 78px;
    margin: 40px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
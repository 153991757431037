<template>
    <metainfo />

    <!-- <div class="orders">
        <h1>{{ $t("orders.orders") }}</h1>
        <hr />
    </div> -->
    <load-ring v-if="isLoading" class="loadProducts" />
    <div v-else-if="state.order.orders?.length <= 0" class="noPage">
        <h2>{{ $t("orders.noOrders") }}</h2>
    </div>
    <div v-else>
        <div class="order">
            <div v-for="order in state.order.orders" :key="order" class="ordersContainer">
                <div class="orderFlex">
                    <img :src="order.img" class="productImg" />
                    <div class="productText">
                        <v-avatar :color="color(order.status)" class="mr-2 v-avatar--metronome" size="12">
                        </v-avatar><span style="color: gray;">{{ new Date(order.created * 1000).toLocaleString() }}</span>
                        <br />
                        {{ $t("global.chips") }}:
                        <span style="color: #179f61;">
                            ${{ $filters.formatChip(order.amount) }}
                        </span>
                        <br />
                        {{ $t("global.price") }}: <span style="color: grey;">{{ order.localPrice }}</span>
                        <br />
                        <v-chip size="x-large" color="lime" style="height: auto;" class="my-2">
                            <font-awesome-icon :icon="['fab', 'bitcoin']" size="2xl" class="mr-2"
                                style="margin-left: -18px; color: orange;" />
                            {{ order.btc }} BTC
                        </v-chip>
                        <!-- <p style="color: gray;">Fiyat: <b style="color: burlywood;">{{ order.price }}</b></p> -->
                    </div>
                </div>
                <router-link :to="'/order/' + order.orderId" style="margin-right: 14px;">
                    <v-btn color="#005ac0" rounded style="height: 100px;border-radius: 16px;"><font-awesome-icon
                            :icon="['fas', 'magnifying-glass']" size="xl" class="" /></v-btn>
                </router-link>
            </div>
        </div>

        <!-- <pagination ref="paging" :total="state.order.list?.totalPage || 0" :change="initialize" /> -->

        <div class="text-center py-10">
            <v-pagination ref="pagination" v-model="paging" @update:modelValue="initialize"
                :length="state.order.list?.totalPage || 0" rounded="circle" style="min-width: 400px; max-width: 60%;"
                class="mx-auto">
            </v-pagination>
        </div>

    </div>
</template> 

<script setup>
import { store, state } from "@/store"
import { ref } from "vue";
import { useMeta } from 'vue-meta';
useMeta({ title: 'Siparişlerim - RestChip.com' });

const paging = ref(1)
const isLoading = ref(false)

const initialize = async (value) => {
    paging.value = value
    isLoading.value = true
    await store.dispatch("order/getOrders",
        {
            clientId: state.clientId,
            page: paging.value - 1 //paging?.value?.current - 1 || 0
        });
    isLoading.value = false
}

initialize(paging.value);

const color = (state) => {

    switch (state) {
        case "waiting": return "blue"
        case "complete": return "green"
        default: return "red"
    }

}

</script> 




<style scoped>
.orders {
    text-align: center;
    padding: 10px 20px;
}

.ordersContainer {
    display: flex;
    align-items: center;
    background-color: #f9cb00d8;
    padding: 0.5rem 0;
    border-radius: 1rem;
    background: #89c8ff24;
    position: relative;
    justify-content: space-between;
    overflow: hidden;
}

.orderFlex {

    display: flex;
    gap: 10px;
    align-content: center;
    justify-content: center;
    align-items: center;
}
</style>
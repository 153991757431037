import { createStore } from 'vuex'
import products from "./modules/product";
import payments from "./modules/payment";
import order from "./modules/order";
import restpoker from "./modules/restpokerUser";

// import payments from "./payment";
// import products from "./product";
// import success from "./success";
// import user from "./user";

export const mutationTypes = {
  ADD_PENDING: "ADD_PENDING",
  REMOVE_PENDING: "REMOVE_PENDING",
};

export const store = createStore({
  modules: {
    products,
    payments,
    order,
    restpoker,
  },
  state: {
    pendings: [],
    packs: [],
    progress: false,
    loginDialog: false,
    currency: localStorage.getItem("currency") || "USD",
    clientId: localStorage.getItem("c") || null,
    currencies: [
      { symbol: '$', currency: 'USD', country: 'en-US' },
      { symbol: '€', currency: 'EUR', country: 'en-US' },
      { symbol: '£', currency: 'GBP', country: 'en-UK' },
      { symbol: '₺', currency: 'TRY', country: 'tr-TR' },
      { symbol: 'Rp', currency: 'IDR', country: 'id-ID' },
    ]
  },
  getters: {
  },
  mutations: {
    [mutationTypes.ADD_PENDING](state, pending) {
      state.progress = true;
      state.pendings.push(pending);
    },
    [mutationTypes.REMOVE_PENDING](state, pending) {
      state.progress = false;
      state.pendings.splice(state.pendings.indexOf(pending), 1);
    },
  },
  actions: {
  }
});

export const state = store.state;

export default { store, state }
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import LoadChip from './components/LoadChip';
import LoadRing from './components/LoadRing';
import Banner from './components/Banner'
import ProductsList from './components/Main'
import CountryFlag from 'vue-country-flag-next'
//import vuetify from './plugins/vuetify';
import { Tabs, Tab } from 'vue3-tabs-component';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import iPagination from './components/pagination.vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBars, fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { faB, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faBitcoin } from '@fortawesome/free-brands-svg-icons'
library.add(faB, fas, faUserSecret, faBars, faBitcoin, far)

import i18n from './i18n'



import { appAxios } from "@/store/modules/baseApi";

import "@/assets/style.css";
import "@/assets/tabMenu.css";
import { aliases, fa } from 'vuetify/iconsets/fa'

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa, faB, fas, faUserSecret, faBars, faBitcoin
        },
    },
})
const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('tabs', Tabs);
app.component('tab', Tab);
app.component('load-chip', LoadChip);
app.component('load-ring', LoadRing);
app.component('banner', Banner);
app.component('product-list', ProductsList);
app.component('country-flag', CountryFlag);
app.component('pagination', iPagination);
app.use(createMetaManager());
app.use(metaPlugin);
app.use(i18n);
app.use(router);
app.use(store);
app.use(vuetify);

app.config.globalProperties.$filters = {
    formatChip(value) {
        return value ? Number(value).toLocaleString() : value;
    },
    toChipKMB(v) {
        if (v < 1000)
            return v
        const prefixes = ["", "K", "M", "B", "T"];
        const exponent = Math.floor(Math.log10(Math.abs(v)) / 3);
        const prefix = prefixes[exponent];
        const shortNumber = (v / Math.pow(10, exponent * 3)).toFixed(0);
        return shortNumber + "" + prefix;
    },
}
app.config.globalProperties.$appAxios = appAxios;
//app.config.globalProperties.$meta = VueMeta;

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

String.prototype.replaceAll = function (find, replace) {
    return this.valueOf().replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

String.prototype.generateUUID = () => {
    var d = new Date().getTime();
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

app.mount("#app");


<template>
    <div class="text-center py-10">
        <v-pagination ref="pagination" v-model="page" @update:modelValue="selectPage" :length="10" rounded="circle"
            style="min-width: 400px; max-width: 60%;" class="mx-auto">
        </v-pagination>
    </div>
</template> 

<script setup>
import { ref, defineProps, defineExpose } from 'vue';

const props = defineProps({
    total: { type: Number, required: true },
    change: Function,
});

const pagination = ref(null)
const page = ref(1)

const selectPage = async (value) => {
    page.value = value

    setTimeout(() => {
        props?.change();
    }, 1001);
}

defineExpose({ current: page })
</script>  
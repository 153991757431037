import Api from "./baseApi";

const state = {
    list: null,
    orders: null,
    order: null,
};

const getters = {
    setting: (state) => {
        return state.setting;
    },
};

const actions = {
    getOrder: async (context, orderId) => {
        state.order = null;
        context.commit("setOrder", (await Api.get(`/api/Payment/Order/${orderId}`)));
    },
    getOrders: async (context, params) => {
        context.commit("setOrders", (await Api.get(`/api/Payment/Orders/${params.clientId}/${params.page}`)));
    },
};

const mutations = {
    setOrder(state, response) {
        if (response.status == 200)
            state.order = response.data;
    },
    setOrders(state, response) {
        if (response.status == 200) {
            state.list = response.data;
            state.orders = response.data.list;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

import Api from "./baseApi";

const state = {
    all: [],
    detail: {},
    response: null,
    order: {
        id: 0,
        price: 0,
        localPrice: "",
        amount: 0,
        btc: 0,
        img: "",
    }
};

const getters = {
    setting: (state) => {
        return state.setting;
    },
};

const actions = {
    buyProduct: async (context, data) => {
        await context.commit("buyProduct", await Api.post(`/api/payment`, data));
    }
};

const mutations = {
    getAll(state, settings) {
        state.all = settings;
    },
    buyProduct(state, response) {
        state.response = response.data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
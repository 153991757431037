<template>
    <div class="loginBox" v-if="state.restpoker.isLogin">
        <div class="profileCardContainer">

            <div class="box">

                <img :src="user.picture" alt="avatar" class="avatar" style="border: 2px solid #ffffff;" />

                <div class="contentsContainer">
                    <div class="contentContainer">
                        <!-- <div class="imgContainer">
                            <country-flag :country='user.flag' size='big' />
                        </div> -->
                        <p><span style="color:yellow;">{{ user.level }}</span>
                            {{ user.name }}
                        </p>
                    </div>
                    <div class="contentContainer">
                        <!-- <div class="imgContainer">
                        <img src="https://cdn.restchip.com/assets/img/chip.png" alt="chip" class="chipImg" />
                    </div> -->
                        <p style="text-wrap: nowrap;color:lime;">$ {{ $filters.formatChip(user.chip) }}</p>
                    </div>
                </div>
                <div class="userBoxId">
                    <span class="lime">ID:</span> {{ user.userId }}
                </div>
                <img src="https://cdn.restchip.com/assets/img/close.png" alt="" class="close" @click="logout" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { store, state } from "@/store"

const userId = ref(localStorage.getItem("RestPokerId"))
const user = computed(() => state.restpoker.user)

const logout = () => store.dispatch("restpoker/logout")

const login = async () => {
    if (userId.value && userId.value.length >= 5 && !state.restpoker.isLogin)
        await store.dispatch("restpoker/login", userId.value);
}

login()

</script>
<template>
  <metainfo />
  <!-- <load-ring v-if="$store.state.products.isListLoading" class="loadProducts" />
  <div v-else>
     <banner />
    <product-list /> 
  </div> -->
  <load-chip v-if="state.products.isListLoading" class="loadProducts" />
  <div v-else style="margin-top: -10px;">
    <!-- <img class="productBanner" :src="product.pic_Banner" /> -->

    <div v-show="!state.loginDialog">
      <div style="position: relative;text-align: center;">
        <img src="https://cdn.restchip.com/assets/img/products/rectangle/restpoker2.png" alt="RestPoker"
          style="max-width: 1000px;width: 100%;margin-bottom: -7px; " />


        <!-- <img src="https://cdn.restchip.com/assets/img/restpokericon.png" alt="RestPoker" class="restPokerLogo"
        style="position: absolute;" /> -->
        <div class="loginBoxParent">
          <RestPokerUser />
        </div>
      </div>
      <v-tabs fixed-tabs bg-color="#005ac0" v-model="tab" class="mx-5" style="border-radius: 10px;">
        <v-tab value="tab1"><b>{{ $t("home.buy") }}</b></v-tab>
        <v-tab value="tab2"><b>{{ $t("home.sell") }}</b></v-tab>
        <v-tab value="tab3"><b>{{ $t("home.orders") }}</b></v-tab>
      </v-tabs>
      <v-window v-model="tab" :touch="false">
        <v-window-item value="tab1">
          <!-- <div class="productItems">
            <ProductCard v-for="(  item, i  ) in   product.products  " :key="i" :product="item" />
            <PackgaeCreater />
          </div> -->
          <div style="padding: 30px;">
            <Carousel v-bind="settings" :breakpoints="breakpoints"  >
              <Slide v-for="(  item, i  ) in   product.products" :key="i">
                <!-- <div class="carousel__item">{{ i }}</div> -->
                <ProductListItem class="carousel__item"   :product="item" />
              </Slide>
              <template #addons>
                <Navigation />
                <Pagination />
              </template>
            </Carousel>
          </div>
        </v-window-item>
        <v-window-item value="tab2">
          <div style="text-align: center;margin: 100px;font-size: 40px;color: rgba(128, 128, 128, 0.401);">
            {{ $t("global.comingSoon") }}
          </div>
        </v-window-item>
        <v-window-item value="tab3">
          <Orders />
        </v-window-item>
      </v-window>
    </div>
    <LoginDialog :dialog="state.loginDialog" />
  </div>
</template>

<script setup>
// import { useMeta } from 'vue-meta'
// useMeta({
//   title: 'Oyunlar - RestChip.com'
// }) 

import { ref, computed, onUnmounted } from "vue";
import { state } from '@/store'
import ProductCard from "@/components/ProductCard";
import ProductListItem from "@/components/ProductList";
import PackgaeCreater from "@/components/PackgaeCreater";
import RestPokerUser from '@/components/RestPokerUser';
import LoginDialog from '@/components/LoginDialog';
import Orders from '@/components/Orders';
import { useMeta } from 'vue-meta'

import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

const tab = ref(null);
const product = computed(() => state.products?.all?.find(x => x.id == 1) || null);


const settings= {
      itemsToShow: 1,
      snapAlign: 'center',
    };
    const   breakpoints = {
      400: {
        itemsToShow: 1,
        snapAlign: 'center',
      }, 
      500: {
        itemsToShow: 1.6,
        snapAlign: 'center',
      },
      600: {
        itemsToShow: 1.9,
        snapAlign: 'center',
      },
      700: {
        itemsToShow: 2,
        snapAlign: 'center',
      },
      800: {
        itemsToShow: 2.2,
        snapAlign: 'center',
      },
      900: {
        itemsToShow: 2.5,
        snapAlign: 'center',
      },
      1000: {
        itemsToShow: 3,
        snapAlign: 'center',
      },
    };

onUnmounted(() => state.loginDialog = false)
const computedMeta = computed(() => ({
  title: state.products.isListLoading ? 'Loding...' : `${product?.value?.name || '404'} - RestChip.com`,
  description: '-',
  meta: [
    { property: 'og:title', content: `${product?.value?.name || '404'} - RestChip.com` },
    { property: 'og:site_name', content: 'restchip.com' },
    { property: 'og:description', content: '-' },
    { property: 'og:type', content: 'product' },
    { property: 'og:url', content: `https://restchip.com/${product?.value?.name || '404'}` },
    { property: 'og:image', content: `${product?.value?.pic_Banner || 'https://cdn.restchip.com/assets/img/products/banner/restpoker.png'}` }
  ]
}))

useMeta(computedMeta) 
</script>

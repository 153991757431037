<template>
    <div class="sliderContainer">
        <carousel :autoplay="3500" :wrapAround="true" :pauseAutoplayOnHover="true">
            <slide v-for="slide in banners" :key="slide">
                <router-link :to="'/' + slide.name" style="width: 100%;">
                    <img style="width: 100%;" :src="slide.pic_Banner" />
                </router-link>
            </slide>

            <template #addons>
                <navigation />
            </template>
        </carousel>
    </div>
</template>

<style>
.sliderContainer {
    max-width: 1000px;
    margin: auto;

    .slider {
        width: inherit;
        height: inherit;
    }
}
</style>
 
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
// import { useStore } from 'vue';

// const store = useStore();
export default
    {
        name: 'mainSlider',
        components: {
            Carousel,
            Slide,
            Navigation,
        },
        computed:
        {
            banners() {
                return this.$store.state.products.all
                    .map(value => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value);
            }
        },
        data: function () {
            return {

            }
        }
    }
</script>
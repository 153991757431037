<template>
    <div class="navBar">
        <div class="navContainer">
            <router-link to="/" class="homeLink">
                <img src="https://cdn.restchip.com/assets/img/chipanim.gif" alt="chio" class="chipAnim" />
                <img src="https://cdn.restchip.com/assets/img/Logo.svg" alt="logo" class="logo" />
            </router-link>
            <select class="select exchange" @input="onExchange" v-model="state.currency">
                <option v-for="item in state.currencies" v-bind:key="item.currency" v-bind:value="item.currency">
                    {{ item.symbol + " " + item.currency }}
                </option>
            </select>
        </div>
    </div>
</template>


<script setup>
import { state } from '@/store'
import i18n from '../i18n'

const onExchange = (e) => {
    if (e.target.selectedIndex > -1) {
        state.currency = e.target.value;
        localStorage.setItem("currency", e.target.value);

        var locale = e.target.value == "TRY" ? "tr" : "en"
        i18n.global.locale = locale
        localStorage.setItem("locale", i18n.global.locale)
    }
}

// const locales = () =>
//     i18n.global.availableLocales.map(local => { return { name: i18n.global.t(`locale.${local}`), val: local } })
// const onChange = () => localStorage.setItem("locale", i18n.global.locale)

const getUserLocale = () => {
    const locale = window.navigator.language ||
        window.navigator.userLanguage || "en-US"
    return {
        locale: locale,
        localeNoRegion: locale.split('-')[0]
    }
}

var locale = localStorage.getItem("locale")
if (locale) {
    i18n.global.locale = locale
}
else {

    locale = getUserLocale()
    locale = i18n.global.availableLocales.find(x => x == locale.localeNoRegion)
    i18n.global.locale = locale

    if (locale == "tr")
        state.currency = "TRY"
}

</script>


<style>
.navBar {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #221958f7;
    position: fixed;
    z-index: 10;
    box-shadow: 0px 4px 15px 1px #0a0222;
    top: 0;
}

.homeLink {
    display: flex;
}

.navContainer {
    width: 1000px;
    margin: auto;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-direction: row;
}

.exchange {
    width: 150px !important;
}


.logo {
    width: clamp(150px, 30vw, 175px);
}

.chipAnim {
    margin-right: 10px;
    width: clamp(60px, 30vw, 60px);
    height: clamp(60px, 30vw, 60px);
}
</style>
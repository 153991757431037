<template>
    <div class="footer">

        <div class="footerBox">
            <router-link to="/">{{ $t("footer.home") }}</router-link>
            <!-- <router-link to="/orders">{{ $t("footer.orders") }}</router-link> -->
            <router-link to="/About">{{ $t("footer.about") }}</router-link>
            <router-link to="/Contact">{{ $t("footer.contact") }}</router-link>
        </div>
        <!-- <v-select v-model="$i18n.locale" :items="locales()" @update:modelValue="onChange" item-title="name" item-value="val"
            dense>
            <template v-slot:append-inner>
                <div style="margin-right: -25px;">
                    <font-awesome-icon :icon="['fas', 'caret-down']" />
                </div>
            </template>
        </v-select> -->
        <div style="margin:   0;text-align: center;">
            <img src="https://cdn.restchip.com/assets/img/btc2.png" alt="We Accept Bitcoin" />
        </div>
        <h6 class="footerHeader">© 2022 Rest Chip. {{ $t("footer.allRights") }}</h6>
        <div v-html="$t('footer.reCapthca')"></div>

    </div>
</template>


<script setup>


</script>

<style>
.footer {
    background-color: #221958bf;
    width: 100%;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: stretch;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding: 30px 10px 10px 10px;
    text-align: center;
    margin-top: auto;
    min-height: 260px;
}

.footer hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.footerBox {
    width: 100%;
    padding: 0 .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}


.footerHeader {
    font-size: clamp(0.875rem, 1vw + 0.125rem, 1.5rem);
    text-align: center;
    color: #ffffff52;
}

.footerTitle {
    color: #9C8BCF;
    text-align: center;
    margin-top: .5rem;
    padding-bottom: 2rem;
}
</style>
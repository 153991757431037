<template>
  <metainfo />
  <div class="about">
    <img style="max-width: 300px;" src="https://cdn.restchip.com/assets/img/Logo.svg" /><span>.com</span>

    <h2 style="padding:20px;font-size:24px; ">Hakkımızda</h2>
    <hr>
    <div style="margin:40px auto;font-size:18px;padding:0 10px;">

      <p>
        Hızlı büyümesini bugün de aynı kararlılıkla sürdüren restchip.com, ucuz ve güvenli Zynga Holdem Texas Poker
        Chip transferi hizmeti ile faaliyet göstermektedir. restchip.com güçlü alt yapısı, kusursuz hazırlanmış
        yazılım programı ve müşteri memnuniyetine dayalı vizyonu ile hızla büyüyen bir chip transfer sitesi haline
        gelmiştir.
      </p>

      <p>
        Hedeflerine ulaşırken güven ve hızını artırarak müşteri memnuniyeti sağlamayı temel ilke kabul etmiştir.
        restchip.com 7/24 hizmet esnasında hiçbir temsilci ile konuşmanıza bile gerek kalmadan, alışverişinizi yapıp
        işlemlerinizin otomatik olarak tamamlanması ile farklılık yaratan bir sitedir.
      </p>

      <p>
        restchip.com Zynga Texas Holdem Poker chip transfer hizmetinde, işinde uzman bir ekip ve güvenli yazılımın
        uyum içerisinde çalışmasının sonucunda müşterilerimize en iyi hizmet verebilme ve isteklerinizi zamanında
        karşılamayı ilke edindik.
      </p>
    </div>
  </div>
</template>

<style>
.about {
  max-width: 1000px;
  margin: auto;
  margin-top: 85px;
  text-align: center;
  padding: 10px;
  padding: 20px 0;
  border-radius: 2rem;
  padding: 50px 10px;
}


.about p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>

<script setup>
import { useMeta } from 'vue-meta';

useMeta({ title: 'Hakkımızda - RestChip.com' });

</script>

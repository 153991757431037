<template>
  <Navbar />
  <div class="content">
    <router-view />
  </div>
  <Footer />
</template>
 


<script setup>
import { store, state } from '@/store'
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

// let userId = localStorage.getItem("userId");
// if (userId) {
//   store.dispatch("user/getUserById", userId);
// } 

store.dispatch("products/getAll");


if (!state.clientId)
  state.clientId = "".generateUUID().replaceAll('-', '')
localStorage.setItem("c", state.clientId);
</script>
import Api from "./baseApi";
import { store } from "@/store"

const state = {
    all: null,
    isListLoading: false,
    isBuyLoading: false,
    selected: ""
};

const getters = {
    setting: (state) => {
        return state.setting;
    },
};

const actions = {
    getAll: async (context) => {
        state.all = null;
        state.isListLoading = true;
        let list = (await Api.get(`/api/product/getall`)).data;
        context.commit("getAll", list);
    }
};

const Round = (v) => v > 1000000 ? Math.round(v / 1000000) * 1000000 : v;
const ToBTC = (rate, value) => (((rate * value) * 100000000)) / 100000000;

const mutations = {
    getAll(state, data) {
        state.all = data.main;
        store.state.rates = data.rates.rates;
        store.state.packs = [1500000000]

        for (var i = 2; i <= 10000; i++) {
            var amount = parseInt(((i * store.state.packs[0])));
            //amount += parseInt((store.state.packs[store.state.packs.length - 1] * 0.10));
            store.state.packs.push(amount > 1000000 ? Math.round(amount / 1000000) * 1000000 : amount);
        }
        state.isListLoading = false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

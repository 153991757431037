<template>
    <div class="sliderContainer">
        <div class="textBlock">
            <h3 class="title-item">Popüler Ürünler</h3>
            <p>En popüler oyunların ürünlerini her zaman en iyi fiyata satın alın!</p>
        </div>
        <div class="productList">
            <router-link :to="'/' + item.name" class="homeLink" v-for="item in $store.state.products.all" :key="item">
                <img class="productsImg" :src="item.pic_Rectangle" />
            </router-link>
        </div>
    </div>
</template>  
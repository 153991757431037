<template>
    <div class="productItem">
 
        <div class="productItemImg">
            <img :src="product.img" :alt="product.name"  />
            <span v-if="!product.isStock" class="noStock">{{ $t("global.nostock") }}</span>
            <span class="smallAmount">{{ $filters.toChipKMB(product.amount) }}</span>
        </div>

        <div class="productItemText">
            <span class="productItemAmount">
                <b style="color: lime;">${{ $filters.formatChip(product.amount) }}</b>
            </span><br />
            <span><br /> <b style="color: #f9ab07;">{{ product.localePrices[state.currency] }}</b></span>
            <br /> <br />
            <v-chip size="x-large" color="lime" style="height: auto;" class=" ">
                <font-awesome-icon :icon="['fab', 'bitcoin']" size="2xl" class="mr-2"
                    style="margin-left: -18px; color: orange;" />
                {{ product.localePrices["BTC"] }} BTC
            </v-chip>
            <br /><br />
        </div>

        <div style="width: 100%;text-align: center;padding: 20px;">
            <button v-if="!isBuy" @click="buyProduct" :class="activeBtn">{{ $t("global.buy") }}</button>

            <load-ring v-if="isBuy" style="width: 50px;margin: auto;" />
        </div>

        <transition name="bounce">
            <div v-if="loginRequired" class="unlogin">
                <p>RestPoker ID ile giriş gerekli.!</p>
            </div>
        </transition>

    </div>
</template>

<style scoped>
.productItem
{ 
    width: 100%;  
    margin: 10px;
    border-radius: 16px; 
    display: block; 
    background: #c289ff24;
    background: radial-gradient(circle, rgb(246, 72, 147) 0%, rgba(198, 2, 95, 1) 0%, rgba(255, 226, 0, 1) 100%);


    background-image: url('https://cdn.restchip.com/assets/img/productBg1.jpg');
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  
    background: rgb(11,44,128);
background: -moz-linear-gradient(0deg, rgba(11,44,128,1) 0%, rgba(253,45,122,1) 100%);
background: -webkit-linear-gradient(0deg, rgba(11,44,128,1) 0%, rgba(253,45,122,1) 100%);
background: linear-gradient(0deg, rgba(11,44,128,1) 0%, rgba(253,45,122,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0b2c80",endColorstr="#fd2d7a",GradientType=1);
    

}

.productItemImg
{  
    display: block;
    text-align: center;
}

.productItemImg > img
{
    width: 90%;
    text-align: center;
}
.productItemAmount
{
    color: lime;
    font-size: 1.5rem;
    text-shadow: 0px 2px 3px black;
    font-weight: bold;
}

.buyBtn,
.buyBtnDisable {
    background-color: #2e8b57;
    font-weight: bold;
    padding: 10px;
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
    border: none;
    text-align: center;
    text-shadow: 0px 2px 3px black;
     width: 100%;
}

.buyBtn:hover {
    background-color: #5688e5;
}

.buyBtnDisable {
    background-color: #000000a6;
    color: #ffffffa6;
    cursor: default;
}

.smallAmount {
    position: absolute;
    margin: 10px;
    padding: 10px;
    background-color: red;
    border-radius: 10px 0 0 10px;
    right: 0;
    top: 50px;
    font-size: 1.5rem;
    font-weight: bold;
    text-shadow: 0px 2px 3px black;
    min-width: 75px;

    background-image: linear-gradient(to right, red , yellow);
   
    background: rgb(166,0,255);
background: -moz-linear-gradient(90deg, rgba(166,0,255,1) 0%, rgba(116,9,121,1) 71%, rgba(36,0,0,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(166,0,255,1) 0%, rgba(116,9,121,1) 71%, rgba(36,0,0,1) 100%);
background: linear-gradient(90deg, rgba(166,0,255,1) 0%, rgba(116,9,121,1) 71%, rgba(36,0,0,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a600ff",endColorstr="#240000",GradientType=1);
}
</style>

<script setup>

import { ref, reactive, defineProps } from 'vue';
import { store, state } from '@/store'

const props = defineProps({ product: { type: Object } })
const loginRequired = ref(false)
const isBuy = ref(false)

const activeBtn = reactive({
    buyBtn: props.product.isStock,
    buyBtnDisable: !props.product.isStock,
    "elevation-8": true
})

const buyProduct = async () => {

    if (!props.product.isStock)
        return;

    state.payments.order = {
        id: props.product.id,
        price: props.product.price,
        localPrice: props.product.localePrices[state.currency],
        amount: props.product.amount,
        btc: props.product.localePrices["BTC"],
        img: props.product.img,
    }
    state.loginDialog = true
}
</script>
